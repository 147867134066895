import React from 'react'

const Footer = () => {
  return (
   <>
   <div className='bg-[#eeeeee] px-4 py-8 text-center'>
    <span className='text-sm text-black font-semibold'>&#169; 2024 Apps</span>
   </div>
   </>
  )
}

export default Footer