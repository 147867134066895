import { createSlice } from "@reduxjs/toolkit";

const userRegSlice = createSlice({
    name: "UserRegSlice",
    initialState: {
        id: 0,
        username: "",
        points: "",
        usercode: "",
        token: "",
     
    },
    reducers: {
        setUserDetails(state, action) {
            // console.log(action);
            state.id = action.payload.id
            state.username = action.payload.username
            state.points = action.payload.points
            state.usercode = action.payload.usercode
            state.token = action.payload.token
        },
        setUserPoints(state, action) {
            state.points = action.payload
        },
       
        
    }
})

export const userRegSliceAction = userRegSlice.actions;
export default userRegSlice;