import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router";
import { toast } from "react-toastify";
import bgBlue from "../assets/images/bgGrey.jpg";
import { httpClient } from "../axios";
import MainNavbar from "../components/MainNavbar";
import { userRegSliceAction } from "../store/userRegSlice";
import Footer from "./Footer";

const Checkout = () => {
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const params = useParams();
  const location = useLocation();
  const USER_DATA = useSelector((state) => state.userReg);
  const [finalPrice, setFinalPrice] = useState(0);
  const [points, setPoints] = useState(0);
  const [pointsValue, setPointsValue] = useState(0);
  const [coupon, setCoupon] = useState();
  const [couponValue, setCouponValue] = useState(0);
  const [showCoupon, setShowCoupon] = useState(false);
  const [diableCoupon, setDisableCoupon] = useState(false);
  const [dialogType, setDialogType] = useState("");
  //   console.log(params);
  // console.log(location);
  const dispatch = useDispatch();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  useEffect(() => {
    getFinalValue(location?.state);
  }, [location]);

  const getFinalValue = (plan) => {
    if(params?.type === 'plan'){
      if (Number(plan.percentagediscount) > 0) {
      let percentDisc =
        Number(plan.planvalue) * (Number(plan.percentagediscount) / 100);
      setFinalPrice(Number(plan.planvalue) - percentDisc);
    } else {
      setFinalPrice(Number(plan.planvalue) - Number(plan.flatdiscount));
    }} else {
      setFinalPrice(Number(plan?.eventvalue) - Number(plan?.discount))
    } 
  };

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  const applyCoupon = (planId) => {
    let testData = {
      couponcode: coupon,
      planid: planId,
      eventid: planId,
      userid: USER_DATA?.id,
      companyid: companyData?.id,
    };

    // console.log(testData);
    httpClient.post(`/validateCoupon`, testData, config).then((res) => {
      // console.log(res);
      if (res.data.statusCode === "200") {
        // setPlansList(res.data.result);
        if (res.data.result.length > 0) {
          setCouponValue(res.data.result[0].couponvalue);
          setDisableCoupon(true);
          setFinalPrice(
            (final) => final - Number(res.data.result[0].couponvalue)
          );
          // getFinalValue(plansList.find((a)=> a.id === planId))
        }
      }

      toast.success(res.data.message);
    });
  };

  const applyPoints = () => {
    let testData = {
      flex1: USER_DATA?.username,
      flex8: USER_DATA?.id,
      flex7: USER_DATA?.points,
    };

    // console.log(testData);
    httpClient.post(`/pointRedeem`, testData, config).then((res) => {
      console.log(res);
      if (res.data.statusCode === "200") {
        if (res.data.result) {
          setPointsValue(res.data.result);
          dispatch(userRegSliceAction.setUserPoints(0));
          setFinalPrice((final) => final - Number(res.data.result));
        }
      }

      toast.success(res.data.message);
    });
  };

  const couponOnChange = (e) => {
    setCoupon(e.target.value);
    setDisableCoupon(false);
  };

  const openDialog = (type) => {
    setDialogType(type);
    onOpen();
  };

  const addCouponPoints = () => {
    onClose();
    dialogType === "coupon" ? applyCoupon() : applyPoints();
  };

  return (
    <div
      className=""
      style={{
        background: `url(${bgBlue})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MainNavbar
        companyName={companyData.companyname}
        companyLogo={companyData.logo}
      />
      <div className="flex flex-col items-center justify-center w-full h-screen">
        <Card className="p-3">
          <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
            {/* <p className="text-tiny uppercase font-bold">Daily Mix</p> */}
            <small className="text-default-500 text-lg">
              {params?.type === 'plan' ? location?.state?.plantype : location?.state?.city}
            </small>
            <h4 className="font-bold  text-3xl">{params?.type === 'plan' ? location?.state?.planname : location?.state?.eventname}</h4>
          </CardHeader>
          <CardBody className="overflow-visible py-2">
            <Image
              alt="Card background"
              className="object-cover rounded-xl"
              src={location?.state?.photo}
              width={570}
            />
            <div className="flex flex-col gap-8 pt-4">
              <div>
                <p className="text-default-500 text-xl font-semibold">Apply Coupon</p>
              <Input
                placeholder="Coupon Code"
                value={coupon}
                onChange={(e) => couponOnChange(e)}
                endContent={
                  <Button
                    isDisabled={diableCoupon}
                    onClick={() => applyCoupon(location?.state?.id)}
                  >
                    Apply
                  </Button>
                }
                className="w-1/2"
              />
              </div>
              <Table
                hideHeader
                removeWrapper
                color="primary"
                aria-label="Example static collection table"
              >
                <TableHeader>
                  <TableColumn>NAME</TableColumn>
                  <TableColumn>STATUS</TableColumn>
                </TableHeader>
                <TableBody>
                  <TableRow key="1">
                    <TableCell className="text-default-500 text-xl font-semibold">
                      Price
                    </TableCell>
                    <TableCell className="text-default-700 text-2xl font-semibold">
                      ${params?.type === 'plan' ? location?.state?.planvalue : location?.state?.eventvalue}
                    </TableCell>
                  </TableRow>
                  <TableRow key="2">
                    <TableCell className="text-default-500 text-xl font-semibold">
                      Discount
                    </TableCell>
                    <TableCell className="text-green-700 text-2xl font-semibold">
                      - $
                     {params?.type === 'plan' ? Number(location?.state?.percentagediscount) > 0
                        ? Number(location?.state?.planvalue) *
                          (Number(location?.state?.percentagediscount) / 100)
                        : Number(location?.state?.flatdiscount) : location?.state?.discount}
                    </TableCell>
                  </TableRow>
                  <TableRow key="3">
                    <TableCell className="text-default-500 text-xl font-semibold">
                      Coupon Code
                    </TableCell>
                    <TableCell className="text-default-700 text-2xl font-semibold ">
                      <a
                        className="text-lg  cursor-pointer flex  items-center gap-4"
                      >
                        <span className="text-green-700 text-2xl font-semibold">
                          - ${couponValue}
                        </span>
                      </a>
                      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
                        <ModalContent>
                          {(onClose) => (
                            <>
                              <ModalHeader>
                                {dialogType === "coupon" &&
                                  "Enter your coupon code here:"}
                              </ModalHeader>
                              <ModalBody>
                                {dialogType === "coupon" ? (
                                  <Input
                                    placeholder="Coupon Code"
                                    value={coupon}
                                    onChange={(e) => couponOnChange(e)}
                                    endContent={
                                      <Button
                                        isDisabled={diableCoupon}
                                        onClick={() =>
                                          applyCoupon(location?.state?.id)
                                        }
                                      >
                                        Apply
                                      </Button>
                                    }
                                  />
                                ) : (
                                  <span className="text-xl text-red-700">
                                    Are you sure?
                                  </span>
                                )}
                              </ModalBody>
                              <ModalFooter>
                                <Button
                                  color="danger"
                                  variant="light"
                                  onPress={onClose}
                                >
                                  Close
                                </Button>
                                <Button
                                  color="primary"
                                  onPress={addCouponPoints}
                                >
                                  ADD
                                </Button>
                              </ModalFooter>
                            </>
                          )}
                        </ModalContent>
                      </Modal>
                    </TableCell>
                  </TableRow>
                  <TableRow key="4">
                    <TableCell className="text-default-500 text-xl font-semibold">
                      Points
                    </TableCell>
                    <TableCell className="text-default-700 text-2xl font-semibold">
                      <a
                        onClick={() => openDialog("point")}
                        className="text-lg text-blue-700 underline cursor-pointer"
                      >
                        {" "}
                        Redeem Points (100 points = $1)
                      </a>
                    </TableCell>
                  </TableRow>
                  <TableRow key="5">
                    <TableCell className="text-default-800 text-xl font-semibold">
                      Total Amount
                    </TableCell>
                    <TableCell className="text-default-800 text-2xl font-semibold">
                      ${finalPrice}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>

              <Button color="primary">Checkout</Button>
              {/* <h1 className="my-4 text-4xl font-bold ">
                ${location?.state?.planvalue}
              </h1>
              <h1 className="my-4 text-4xl font-bold ">
              </h1> */}
            </div>
          </CardBody>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
