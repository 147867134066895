import {
  Accordion,
  AccordionItem,
  Avatar,
  Button,
  Card,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Textarea,
  useDisclosure,
} from "@nextui-org/react";
import React, { useState } from "react";
import emptystar from "../assets/icons/Star.svg";
import solidstar from "../assets/icons/solidStar.svg";
import Rating from "react-rating";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import { httpClient } from "../axios";
import { toast } from "react-toastify";
import bgBlue from "../assets/images/bgGrey.jpg";
import {useSelector} from "react-redux"

const Comments = () => {
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const USER_DATA = useSelector((state) => state.userReg);

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const [event, setEvent] = useState();
  const [showCommentDiv, setShowCommentDiv] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [eventsRatingList, setEventsRatingList] = useState([]);

  const location = useLocation();
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const navigate = useNavigate()
  const params = useParams()

  useEffect(() => {
    setEvent(location?.state?.event);
    getEventsRatings(location?.state?.event);
  }, [location]);

  const changeRating = (e) => {
    // console.log(e);
    setRating(e);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  const getEventsRatings = (event) => {
    httpClient
      .get(`findEventCommentRatebyEventId/${event.id}`, config)
      .then((res) => {
        try {
          // console.log(res);
          if (res.data.statusCode === "200") {
            setEventsRatingList(res.data.result);
          }
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
  };

  const AddComment = () => {
    let commData = {
      userid: USER_DATA?.id,
      eventid: event?.id,
      rating: rating,
      comment: comment,
      username: USER_DATA?.username,
      eventname: event?.eventname,
    };
    httpClient.post(`createEventCommentrate`, commData, config).then((res) => {
      try {
        // console.log(res);
        getEventsRatings(event);
        toast.success(res.data.message);
        setShowCommentDiv(false);
        setComment("");
        onClose();
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const gotoBack = () => {
    navigate(`/company-data/events/${companyData.id}`)
  }

  return (
    <div
      className="h-dvh pt-2.5"
      style={{
        background: `url(${bgBlue})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Card className="py-2 px-2 sm:py-4 sm:px-6 sm:my-4 sm:mx-10 h-[95vh] overflow-y-scroll">
        <>
          <div className="flex justify-between">
            <h1 className="flex flex-col gap-1 text-4xl font-semibold mb-3">
              {event?.eventname}
            </h1>
            <Button onClick={gotoBack} variant="bordered" color="danger">Back</Button>
          </div>
          <div>
            <div className="flex flex-col gap-3 mb-4">
              <Image
                className="border-2 border-[#eeeeee]"
                src={event?.photo}
                alt="Event Image"
                height={200}
              />
              <p className="text-[#757575] font-semibold">{event?.eventdesc}</p>
            </div>
            <div className="">
              <Button onPress={onOpen} color="primary">
                Add Comment
              </Button>

              <Accordion
                className="mt-6"
                variant="splitted"
                selectionMode="multiple"
              >
                {eventsRatingList.map((rate, i) => {
                  return (
                    <AccordionItem
                      key={i}
                      aria-label={rate?.username}
                      subtitle={`Rating : ${rate?.rating}`}
                      title={rate?.username}
                      startContent={
                        <Avatar
                        color="primary"
                          name={rate?.username}
                          getInitials={(name) => name.charAt(0).toUpperCase()}
                        />
                      }
                    >
                      <div className="flex flex-col">
                        <Rating
                          start={0}
                          stop={5}
                          readonly
                          initialRating={rate?.rating}
                          emptySymbol={<img src={emptystar} className="icon" />}
                          fullSymbol={<img src={solidstar} className="icon" />}
                        />
                        <span>{rate?.comment}</span>
                      </div>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </>
        
      </Card>

      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Comment
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-5 mt-4">
                  <Rating
                    start={0}
                    stop={5}
                    onChange={changeRating}
                    emptySymbol={<img src={emptystar} className="icon" />}
                    fullSymbol={<img src={solidstar} className="icon" />}
                  />
                  <Textarea
                    placeholder="Add Comment"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="primary" onClick={AddComment}>
                  ADD COMMENT
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
};

export default Comments;
