import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userRegSlice from './userRegSlice';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  blacklist: [

  ],
};

const reducer = combineReducers({
  userReg: userRegSlice.reducer,
  
});

const persistedReducer = persistReducer(persistConfig, reducer);


const store = configureStore({
  reducer: persistedReducer,
});

export default store;
