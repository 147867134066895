import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  redirect,
  useLocation,
  useNavigate,
} from "react-router-dom";
import MainHomepage from "./pages/MainHomepage";
import MainNavbar from "./components/MainNavbar";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Company from "./pages/Company";
import { useEffect } from "react";
import CompanyData from "./pages/CompanyData";
import Events from "./pages/Events";
import Plans from "./pages/Plans";
import Comments from "./pages/Comments";
import Checkout from "./pages/Checkout";

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname == "/" || location.pathname == "") {
      navigate("/login");
    }
  }, [location]);

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/login" element={<Login />} />
      <Route path="/comments" element={<Comments />} />
      <Route path="/company" element={<Company />} />
      <Route path="/checkout/:type" element={<Checkout />} />
      <Route path="/company-data/:type/:id" element={<CompanyData />} />
      {/* <Route path="/events" element={<Events />} />
      <Route path="/plans" element={<Plans />} /> */}

      <Route path="/home" element={<MainHomepage />} />
    </Routes>
  );
}

export default App;
