import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { httpClient } from "../axios";
import MainNavbar from "../components/MainNavbar";
import CompanyProfile from "./CompanyProfile";
import Events from "./Events";
import Footer from "./Footer";
import Plans from "./Plans";
import { toast } from "react-toastify";
import Marquee from "react-fast-marquee";
import bgBlue from "../assets/images/bgGrey.jpg";
import { Avatar, Chip, Listbox, ListboxItem } from "@nextui-org/react";
import {useSelector} from "react-redux"

const CompanyData = () => {
  const location = useLocation();
  const params = useParams();
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const [type, setType] = useState("");
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const USER_DATA = useSelector((state) => state.userReg);
  const [topUsers, setTopUsers] = useState([]);

  useEffect(() => {
    setType(params?.type);
    getTopFiveUsers();
  }, [params]);

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  const getTopFiveUsers = () => {
    httpClient.get(`/getTopFiveUsers`, config).then((res) => {
      try {
        // console.log(res);
        if (res.data.statusCode === "200") {
          setTopUsers(res.data.result);
        }
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  return (
    <div
      className=""
      style={{
        background: `url(${bgBlue})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <MainNavbar
        companyName={companyData.companyname}
        companyLogo={companyData.logo}
      />
      <div className="testUser ">
        <div className=" p-10">
          <h1 className="text-[#1D1D1F] m-0 text-4xl">Top Five Users</h1>

          <div className="py-5 flex flex-col md:flex-row justify-center gap-4 items-center w-full flex-wrap ">
            {topUsers.map((user) => {
              return (
                <Chip
                  className="py-6 px-3 "
                  color="primary"
                  variant="shadow"
                  size="lg"
                  avatar={
                    <Avatar
                      name={user?.username}
                      getInitials={(name) => name.charAt(0).toUpperCase()}
                    />
                  }
                >
                  <div className="flex items-center">
                    <div className="flex flex-col pl-3">
                      <span className="leading-4 text-white text-xl">
                        {user?.username}
                      </span>
                      <span className="leading-5 text-warning-50 text-sm">
                        {user?.email}
                      </span>
                    </div>
                    <span className="text-3xl pl-3">{user?.points}</span>
                  </div>
                </Chip>
              );
            })}
          </div>
        </div>
        {/* <CompanyProfile data={location.state?.data} /> */}
        {params.type === "home" ? (
          <div>
            <Plans pageType="home"  company={companyData} />
            <Events pageType="home" company={companyData} />
          </div>
        ) : (
          <div>
            {params.type === "plans" && <Plans pageType="page"  company={companyData} />}
            {params.type === "events" && <Events pageType="page"  company={companyData} />}
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default CompanyData;
