import React, { useEffect, useState } from "react";
import { httpClient } from "../axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/images/rightBack.svg";
import leftArrow from "../assets/images/leftBlack.svg";
import { toast } from "react-toastify";
import {
  Badge,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  CheckboxGroup,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

const Plans = ({ company, pageType }) => {
  const [plansList, setPlansList] = useState([]);
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const USER_DATA = useSelector((state) => state.userReg);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [plan, setPlan] = useState();
  const [coupon, setCoupon] = useState();
  const [couponValue, setCouponValue] = useState();
  const [diableCoupon, setDisableCoupon] = useState(false);
  const [showCoupon, setShowCoupon] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const [points, setPoints] = useState(0);
  const [pointsValue, setPointsValue] = useState(0);

  const [showPoints, setShowPoints] = useState(false);

  const navigate = useNavigate();

  console.log(USER_DATA);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: "60px",
    nextArrow: <img src={rightArrow} alt="left" />,
    prevArrow: <img src={leftArrow} alt="right" />,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    httpClient
      .get(`/getAllPlanByCompanyId/${company?.id}`, config)
      .then((res) => {
        try {
          // console.log(res);
          if (res.data.statusCode === "200") {
            setPlansList(res.data.result);
          }
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
  };

  const openDialog = (dialog) => {
    navigate("/checkout/plan", { state: dialog });
    setPlan(dialog);
    // onOpen();
  };

  const getFinalValue = (plan) => {
    if (Number(plan.percentagediscount) > 0) {
      let percentDisc =
        Number(plan.planvalue) * (Number(plan.percentagediscount) / 100);
      return Number(plan.planvalue) - percentDisc;
    } else {
      return Number(plan.planvalue) - Number(plan.flatdiscount);
    }
  };

  return (
    <>
      <div className="py-20 px-10 ">
        <h1 className="text-[#1D1D1F] m-0 text-4xl">Our Plans</h1>
        <p className="mb-5 text-[#8D8D92] text-xl">
          Building extraordinary products for an extraordinary ...
        </p>

        {/* <Slider {...settings}> */}
        <div
          className={` ${
            pageType == "home" ? "lg:h-[400px] " : "h-auto"
          } flex flex-wrap w-full justify-between overflow-hidden gap-4 `}
        >
          {plansList.map((plan, i) => {
            return (
              <Card
                key={i}
                className=" py-4 px-6 w-[35rem] flex flex-col items-center h-min group hover:border border-[#F31260] cursor-pointer"
              >
                <p className="text-[#757575] text-2xl font-semibold  tracking-wider">
                  {plan?.plantype?.toUpperCase()}
                </p>

                <div className="mt-3">
                  <Badge
                    content={
                      Number(plan.flatdiscount) <= 0 ? (
                        <span>{plan.percentagediscount}% OFF</span>
                      ) : (
                        <span>FLAT {plan.flatdiscount} OFF</span>
                      )
                    }
                    color="danger"
                    size="lg"
                    placement="top-right"
                    className="right-[-5%]"
                  >
                    <div className="flex justify-center items-center">
                      <h4 className="text-[3rem] font-semibold line-through">
                        ${plan?.planvalue}
                      </h4>
                      <h1 className="text-[5rem] font-semibold ">
                        ${getFinalValue(plan)}
                      </h1>
                    </div>
                  </Badge>
                </div>
                {/* <div className="w-full text-end pr-8">
                  <a
                    className=" text-blue-600 underline"
                    
                  >
                    Have a Coupon code?
                  </a>
                </div> */}

                <p className="text-2xl text-blue-950 font-medium mt-3">
                  {plan?.planname}
                </p>

                <div className="flexx flex-col text-center">
                  <ul>
                    <li className="text-xl text-blue-900 font-medium list-disc">
                      {plan?.plandescription}
                    </li>
                  </ul>
                </div>
                <Button
                  className="mt-3 group-hover:bg-[#F31260]"
                  color="primary"
                  onClick={() => openDialog(plan)}
                >
                  Subscribe Now
                </Button>
              </Card>
            );
          })}
        </div>
        {/* </Slider> */}
      </div>
    </>
  );
};

export default Plans;
