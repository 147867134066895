import React from "react";
import ImageDiv from "../components/ImageDiv";
import {
  Fullpage,
  FullpageSection,
  FullPageSections,
} from "@ap.cx/react-fullpage";
import image1 from "../assets/images/1.jpg";

import ProdSlider from "../components/ProdSlider";
import MainNavbar from "../components/MainNavbar";
import Footer from "./Footer";


const MainHomepage = () => {
  return (
    <>
      <MainNavbar />

      <Fullpage>
        <FullPageSections>
          <FullpageSection
            style={{
              backgroundColor: "white",
              paddingTop: "2.875rem",
            }}
          >
            <ImageDiv image={image1} />
          </FullpageSection>

          <FullpageSection
            style={{
              backgroundColor: "#F5F5F7",
              paddingTop: "2.875rem",
              
            }}
            
          >
            <div className="flex flex-col justify-between" style={{height: '-webkit-fill-available'}}>
            <ProdSlider />

      <Footer />
      </div>
          </FullpageSection>

        </FullPageSections>
      </Fullpage>

    </>
  );
};

export default MainHomepage;
