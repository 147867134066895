import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { httpClient } from "../axios";
import ProdSlider from "../components/ProdSlider";
import { useDispatch, useSelector } from 'react-redux';

const Company = () => {
  const [companies, setCompanies] = useState([]);
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));

  const USER_DATA = useSelector((state) => state.userReg);

  // console.log(USER_DATA);

  useEffect(() => {
    getAllCompanies();
  }, []);

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };
  const getAllCompanies = () => {
    console.log(config);
    httpClient.get("/getAllCompany", config).then((res) => {
      try {
        setCompanies(res.data.result);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };
  return (
    <div className="bg-[#eeeeee] h-dvh">
      <ProdSlider listItems={companies} />
    </div>
  );
};

export default Company;
