import {
  Button,
  Card,
  Image,
  Input,
  Link,
  Select,
  SelectItem,
  Spacer,
  Textarea,
} from "@nextui-org/react";
import React, { useEffect, useMemo, useState } from "react";
import show from "../assets/icons/Show.svg";
import hide from "../assets/icons/Hide.svg";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { httpClient } from "../axios";
import { useNavigate } from "react-router";
import back from "../assets/images/back1.jpg";

const INTIALIZE = {
  username: "",
  email: "",
  password: "",
  gender: "",
  isactive: "",
  address: "",
  points: 0,
  mobileno: "",
  city: "",
  substatus: "",
  signupcode: "",
};
const validation = Yup.object().shape({
  username: Yup.string().required(),
  email: Yup.string().required(),
  password: Yup.string().required(),
  mobileno: Yup.string().required(),
});

const Register = () => {
  const [genders, setGenders] = useState([
    {
      label: "Male",
    },
    { label: "Female" },
    { label: "Others" },
  ]);

  const [cities, setCities] = useState([]);

  const [city, setCity] = useState("");

  const [gender, setGender] = useState();

  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const handleGenderChange = (e) => {
    setGender(e.target.value);
  };

  const handleCityChange = (e) => {
    setCity(e.target.value);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getAllCities();
  }, []);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: INTIALIZE,
    validationSchema: validation,
    onSubmit: () => {
      saveUser();
    },
  });

  const saveUser = () => {
    const test = {
      id: values.id,
      username: values.username,
      email: values.email,
      password: values.password,
      gender: gender,
      isactive: "Y",
      address: values.address,
      points: values.points,
      mobileno: values.mobileno,
      city: city,
      substatus: "Yes",
      signupcode: values.signupcode,
    };

    httpClient.post("/usersignUp", test).then((res) => {
      try {
        // console.log(res);
        if (res.data.statusCode === "202") {
          navigate("/login");
        }
        toast.success(res.data.message);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const getAllCities = () => {
    httpClient.get("getAllCity").then((res) => {
      // console.log(res);
      if (res.data.statusCode === "200") {
        setCities(res.data.result);
        let cityf = res.data.result.find((a) => a.cityname == "Texas");
        // console.log(cityf);
        setCity(cityf.cityname);
      }
    });
  };

  return (
    <div
      className="flex items-center justify-center h-dvh"
      style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}
    >
      <Card className="p-8 w-[45rem]">
        <h1 className="font-bold text-4xl mb-4">Register</h1>
        <form
          id="reg"
          className="flex flex-col gap-6"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col gap-4 md:flex-row items-start">
            <Input
              isRequired
              label="User Name"
              type="text"
              labelPlacement="outside"
              value={values.username}
              name="username"
              isInvalid={Boolean(touched.username && errors.username)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.username && errors.username) &&
                "Please enter a valid Username"
              }
            />
            <Input
              isRequired
              label="Email"
              type="email"
              labelPlacement="outside"
              value={values.email}
              name="email"
              isInvalid={Boolean(touched.email && errors.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.email && errors.email) &&
                "Please enter a valid email"
              }
            />
          </div>
          <div className="flex flex-col gap-4 md:flex-row items-start">
            <Input
              isRequired
              label="Mobile Number"
              labelPlacement="outside"
              value={values.mobileno}
              name="mobileno"
              isInvalid={Boolean(touched.mobileno && errors.mobileno)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.mobileno && errors.mobileno) &&
                "Please enter a valid mobileno"
              }
            />
            <Input
              isRequired
              label="Password"
              labelPlacement="outside"
              type={isVisible ? "text" : "password"}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <Image alt="Hide" src={hide} />
                  ) : (
                    <Image alt="Show" src={show} />
                  )}
                </button>
              }
              value={values.password}
              name="password"
              isInvalid={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.password && errors.password) &&
                "Please enter a valid password"
              }
            />
          </div>
          <div className="flex flex-col gap-4 md:flex-row items-start">
            <Select
              label="City"
              labelPlacement="outside"
              isRequired
              defaultSelectedKeys={['Texas']}
              onChange={handleCityChange}
            >
              {cities.map((c) => (
                <SelectItem key={c.cityname} value={c.cityname}>
                  {c.cityname}
                </SelectItem>
              ))}
            </Select>
            <Select
              label="Gender"
              labelPlacement="outside"
              isRequired
              onChange={handleGenderChange}
            >
              {genders.map((gender) => (
                <SelectItem key={gender.label} value={gender.label}>
                  {gender.label}
                </SelectItem>
              ))}
            </Select>
          </div>

          <div className="flex gap-4 items-start">
            <Input
              label="Referral Code"
              labelPlacement="outside"
              value={values.signupcode}
              name="signupcode"
              isInvalid={Boolean(touched.signupcode && errors.signupcode)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.signupcode && errors.signupcode) &&
                "Please enter a valid Referral Code"
              }
            />
            <Textarea
              label="Address"
              placeholder="Enter your Address"
              labelPlacement="outside"
            />
          </div>
          <div className="mt-7 flex flex-col ">
            <Button
              className="flex-1 py-2 text-sm"
              type="submit"
              color="primary"
            >
              Register
            </Button>
            <p>
              Already has account ? <Link href="/login">Login</Link>
            </p>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Register;
