import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/images/rightBack.svg";
import leftArrow from "../assets/images/leftBlack.svg";

import { useNavigate } from "react-router";

const ProdSlider = ({ listItems }) => {
const navigate = useNavigate()

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: "60px",
    nextArrow: <img src={rightArrow} alt="left" />,
    prevArrow: <img src={leftArrow} alt="right" />,
  };

  const showCompanyWiseData = (comp) => {
    localStorage.setItem("companyData", JSON.stringify(comp))
navigate(`/company-data/${'home'}/${comp?.id}`,{state:{data:comp}})
  }

  return (
    <div className="py-20 px-10">
      <h1 className="text-[#1D1D1F] m-0 text-4xl">Our Companies</h1>
      <p className="mb-5 text-[#8D8D92] text-xl">
        Building extraordinary products for an extraordinary ...
      </p>

      <Slider {...settings}>
        {listItems.map((comp) => {
          return (
            <div className="card sliderCard mx-4" style={{width:'30rem'}} onClick={() => showCompanyWiseData(comp)}>
             {comp?.logo ? <img src={comp?.logo} alt="logo" className="w-60 h-60 rounded-[50%]" /> : <div className="w-60 h-60 rounded-[50%] bg-blue-300 flex justify-center items-center text-blue-600 text-5xl"><span>{comp?.companyname.charAt(0).toUpperCase()}</span></div>}
              <div className="bottomDiv text-center">
                <p className="text-xl font-medium">{comp?.companyname}</p>
                <p className="text-[#757575] ">{comp?.city}</p>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ProdSlider;
