import { Button, Card, Image, Input, Link } from "@nextui-org/react";
import React from "react";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { httpClient } from "../axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import show from "../assets/icons/Show.svg";
import hide from "../assets/icons/Hide.svg";
import back from "../assets/images/back2.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { userRegSliceAction } from "../store/userRegSlice";

const INTIALIZE = {
  username: "",
  password: "",
};
const validation = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
});

const Login = () => {
  const [isVisible, setIsVisible] = React.useState(false);

  const toggleVisibility = () => setIsVisible(!isVisible);

  const navigate = useNavigate();

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    validateOnChange: true,
    initialValues: INTIALIZE,
    validationSchema: validation,
    onSubmit: () => {
      saveUser();
    },
  });

  const dispatch = useDispatch();

  const saveUser = () => {
    const test = {
      username: values.username,
      password: values.password,
    };

    httpClient.post("/userLogin", test).then((res) => {
      try {
        // console.log(res);
        if (res.data.statusCode === "200") {
          // localStorage.setItem("userData", JSON.stringify(res.data.result));
          dispatch(userRegSliceAction.setUserDetails(res.data.result))
          navigate("/company");
        }
        toast.success(res.data.message);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };
  return (
    <div
      className="flex items-center justify-center h-dvh"
      style={{ backgroundImage: `url(${back})`, backgroundSize: "cover" }}
    >
      <Card className="p-8 w-[35rem]">
        <h1 className="font-bold text-4xl mb-4">Login</h1>
        <form
          id="reg"
          className="flex flex-col gap-6"
          noValidate
          onSubmit={handleSubmit}
        >
          <div className="flex gap-4 items-start flex-col">
            <Input
              isRequired
              label="Email"
              labelPlacement="outside"
              value={values.username}
              name="username"
              isInvalid={Boolean(touched.username && errors.username)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.username && errors.username) &&
                "Please enter a valid username"
              }
            />
            <Input
              isRequired
              label="Password"
              labelPlacement="outside"
              type={isVisible ? "text" : "password"}
              endContent={
                <button
                  className="focus:outline-none"
                  type="button"
                  onClick={toggleVisibility}
                >
                  {isVisible ? (
                    <Image alt="Hide" src={hide} />
                  ) : (
                    <Image alt="Show" src={show} />
                  )}
                </button>
              }
              value={values.password}
              name="password"
              isInvalid={Boolean(touched.password && errors.password)}
              onChange={handleChange}
              onBlur={handleBlur}
              errorMessage={
                Boolean(touched.password && errors.password) &&
                "Please enter a valid password"
              }
            />
          </div>
          <div className="mt-6 flex flex-col">
            <div className="w-full text-right">
              {" "}
              <Link className="" href="/login">
                Forgot Password
              </Link>
            </div>

            <Button
              className="flex-1 py-2 text-sm"
              type="submit"
              color="primary"
            >
              Login
            </Button>
            <p>
              Create an Account ? <Link href="/register">Register</Link>
            </p>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default Login;
