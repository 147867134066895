import React, { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { httpClient } from "../axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import rightArrow from "../assets/images/rightBack.svg";
import leftArrow from "../assets/images/leftBlack.svg";
import emptystar from "../assets/icons/Star.svg";
import solidstar from "../assets/icons/solidStar.svg";
import {
  Accordion,
  AccordionItem,
  Avatar,
  Badge,
  Button,
  Card,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Radio,
  RadioGroup,
  useDisclosure,
} from "@nextui-org/react";
import Rating from "react-rating";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";

const Events = ({ company, pageType }) => {
  const [eventsList, setEventsList] = useState([]);
  const [eventsRatingList, setEventsRatingList] = useState([]);
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const USER_DATA = useSelector((state) => state.userReg);

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [event, setEvent] = useState();
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState(0);
  const [showCommentDiv, setShowCommentDiv] = useState(false);

  const navigate = useNavigate();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    centerMode: true,
    centerPadding: "60px",
    nextArrow: <img src={rightArrow} alt="left" />,
    prevArrow: <img src={leftArrow} alt="right" />,
  };

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  useEffect(() => {
    getAllEvents();
  }, []);

  const getAllEvents = () => {
    httpClient
      .get(`/findEventbyCompanyId/${company?.id}`, config)
      .then((res) => {
        try {
          console.log(res);
          if (res.data.statusCode === "200") {
            setEventsList(res.data.result);
          }
        } catch (err) {
          toast.error("Something went wrong");
        }
      });
  };

  const eventChange = (item) => {
    // console.log(item);
    // getEventsRatings(item);
    navigate("/comments", { state: { event: item } });
    // onOpen();
  };

  const changeRating = (e) => {
    // console.log(e);
    setRating(e);
  };

  const openDialog = (dialog) => {
    navigate("/checkout/event", { state: dialog });
    setEvent(dialog);
    // onOpen();
  };

  const getFinalValue = (plan) => {
    return Number(plan.eventvalue) - Number(plan.discount);
  };
  return (
    <>
      <div className="py-20 px-10 ">
        <h1 className="text-[#1D1D1F] m-0 text-4xl">Our Events</h1>
        <p className="mb-5 text-[#8D8D92] text-xl">
          Building extraordinary products for an extraordinary ...
        </p>

        <div
          className={` ${
            pageType == "home" ? "lg:h-[300px] " : "h-auto"
          } flex flex-wrap w-full justify-between overflow-hidden gap-4 `}
        >
          {eventsList.map((event, i) => {
            return (
              <Card
                key={i}
                className=" py-4 px-6 w-[25rem] flex flex-col items-center h-min group hover:border border-[#F31260] cursor-pointer"
              >
                {/* <img  
                  src={event?.photo}
                  alt="logo"
                  className="w-60 h-60 rounded-[50%]"
                /> */}
                <p className="text-[#757575] text-2xl font-semibold  tracking-wider">
                  {event?.eventtype?.toUpperCase()}
                </p>

                <div className="mt-3">
                  <Badge
                    content={<span>FLAT {event.discount} OFF</span>}
                    color="danger"
                    size="lg"
                    placement="top-right"
                    className="right-[-5%]"
                  >
                    <div className="flex justify-center items-center">
                      <h4 className="text-[3rem] font-semibold line-through">
                        ${event?.eventvalue}
                      </h4>
                      <h1 className="text-[5rem] font-semibold ">
                        ${getFinalValue(event)}
                      </h1>
                    </div>
                  </Badge>
                </div>
                {/* <div className="w-full text-end pr-8">
                  <a
                    className=" text-blue-600 underline"
                    
                  >
                    Have a Coupon code?
                  </a>
                </div> */}

                <p className="text-2xl text-blue-950 font-medium mt-3">
                  {event?.eventname}
                </p>

                <div className="flexx flex-col text-center">
                  <ul>
                    <li className="text-xl text-blue-900 font-medium list-disc">
                      {event?.eventdesc}
                    </li>
                  </ul>
                </div>
                <Button
                  className="mt-3 group-hover:bg-[#F31260]"
                  color="primary"
                  onClick={() => openDialog(event)}
                >
                  Subscribe Now
                </Button>
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Events;
