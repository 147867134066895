import React, { useState } from "react";
import {
  Navbar,
  Link,
  Text,
  Dropdown,
  NavbarMenuToggle,
  NavbarBrand,
  NavbarItem,
  NavbarContent,
  Button,
  NavbarMenu,
  NavbarMenuItem,
  DropdownTrigger,
  DropdownItem,
  DropdownMenu,
  Avatar,
  Modal,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Input,
  Listbox,
  ListboxItem,
} from "@nextui-org/react";
import logo from "../assets/images/logo.png";
import userLogo from "../assets/images/user-regular.svg";
import { Link as ReactLink } from "react-router-dom";
import downArrow from "../assets/images/chevron-down-solid.svg";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { httpClient } from "../axios";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';

const MainNavbar = ({ companyName, companyLogo }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  // const USER_DATA = JSON.parse(localStorage.getItem("userData"));
  const USER_DATA = useSelector((state) => state.userReg);
  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const [refState, setRefState] = useState("ref");
  const [refList, setRefList] = useState([]);
  const [refEmail, setRefEmail] = useState();
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const menuItems = [
    { name: "Home", link: `/home/${companyData?.id}` },
    // { name: "About", link: "" },
    { name: "Our Events", link: `/events/${companyData?.id}` },
    { name: "Our Plans", link: `/plans/${companyData?.id}` },
    // { name: "Contact", link: "/contact" },
  ];

  const changeRoute = (item) => {
    let loc = location.pathname;
    window.location.pathname = `${loc.split("/")[1]}${item.link}`;
  };

  const logout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const config = {
    headers: {
      Authorization: `Bearer ${USER_DATA?.token}`,
      "Remote-User": `${USER_DATA?.username}`,
    },
  };

  useEffect(() => {
    getRefUsersId();
  }, []);

  const submitRef = () => {
    // console.log(refEmail);
    // let currentDate = new Date();

    let testRef = {
      torefemail: refEmail,
      byrefemail: USER_DATA?.username,
      companyid: companyData.id,
      userid: USER_DATA?.id,
      refstatus: "No",
      refrralcode:  USER_DATA?.usercode,
      // refdate: currentDate
    };
    httpClient.post(`addRefrral`, testRef, config).then((res) => {
      try {
        toast.success(res.data.message);

        onClose();
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const getRefUsersId = () => {
    httpClient.get(`getAllRefByUserId/${USER_DATA?.id}`, config).then((res) => {
      try {
        setRefList(res.data.result);
      } catch (err) {
        toast.error("Something went wrong");
      }
    });
  };

  const openRefDialog = (state) => {
    onOpen();
    getRefUsersId()
    setRefState(state);
  };

  const handleRoute = (route) => {
    console.log(route);
  }

  return (
    <>
      <Navbar className="h-[3rem] " onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent className="cursor-pointer">
          <NavbarMenuToggle
            aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            className="sm:hidden"
          />
          <NavbarBrand onClick={() => changeRoute(menuItems[0])}>
            <p className="font-bold text-inherit">
              {companyName ? companyName : "Test"}
            </p>
          </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
          {menuItems.map((item, index) => (
            <NavbarItem className="cursor-pointer" key={item.name}>
              <Link color="foreground" onClick={() => changeRoute(item)}>
                {item.name}
              </Link>
            </NavbarItem>
          ))}
        </NavbarContent>
        <NavbarContent className="hidden md:flex" justify="end">
          <Dropdown>
            <DropdownTrigger>
              <Button
                color="primary"
                variant="bordered"
                startContent={
                  <Avatar
                    size="sm"
                    color="primary"
                    name={USER_DATA?.username}
                    getInitials={(name) => name.charAt(0).toUpperCase()}
                  />
                }
              >
                <div className="flex items-center">
                  {" "}
                  {USER_DATA?.username}{" "}
                  <span className="text-lg font-semibold pl-2">
                    {USER_DATA?.points}{" "}
                  </span>{" "}
                </div>
              </Button>
            </DropdownTrigger>
            <DropdownMenu aria-label="Static Actions">
              <DropdownItem onPress={() => openRefDialog("ref")} key="new">
                Refer a friend
              </DropdownItem>
              <DropdownItem onPress={() => openRefDialog("all")} key="new">
                Your Referrals
              </DropdownItem>

              <DropdownItem
                onClick={logout}
                key="delete"
                className="text-danger"
                color="danger"
              >
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
          {/* <NavbarItem className="hidden lg:flex">
          <Link className="cursor-pointer" onClick={logout}>
            Logout
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Button color="primary" variant="flat">
            Points : {USER_DATA.points}
          </Button>
        </NavbarItem> */}
        </NavbarContent>
        <NavbarMenu>
          {menuItems.map((item, index) => (
            <NavbarMenuItem key={`${item}-${index}`}>
              <Link
                color={
                  index === 2
                    ? "primary"
                    : index === menuItems.length - 1
                    ? "danger"
                    : "foreground"
                }
                className="w-full"
                onClick={() => changeRoute(item)}
                size="lg"
              >
                {item.name}
              </Link>
            </NavbarMenuItem>
          ))}
          <NavbarMenuItem>
            <Dropdown>
              <DropdownTrigger>
                <Button
                  color="primary"
                  variant="bordered"
                  startContent={
                    <Avatar
                      size="sm"
                      color="primary"
                      name={USER_DATA?.username}
                      getInitials={(name) => name.charAt(0).toUpperCase()}
                    />
                  }
                >
                  <div className="flex items-center">
                    {" "}
                    {USER_DATA?.username}{" "}
                    <span className="text-lg font-semibold pl-2">
                      {USER_DATA?.points}{" "}
                    </span>{" "}
                  </div>
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownItem onPress={onOpen} key="new">
                  Refer a friend
                </DropdownItem>

                <DropdownItem
                  onClick={logout}
                  key="delete"
                  className="text-danger"
                  color="danger"
                >
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavbarMenuItem>
        </NavbarMenu>
      </Navbar>
      <Modal
        scrollBehavior="inside"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                {refState === "ref" ? "Refer a Friend" : "Your Referrals"}
              </ModalHeader>
              {refState === "ref" ? (
                <ModalBody>
                  <Input
                    type="email"
                    onChange={(e) => setRefEmail(e.target.value)}
                    placeholder="Enter a Email"
                  />
                </ModalBody>
              ) : (
                <ModalBody>
                  <Listbox
                    aria-label="Listbox Variants"
                    color="default"
                    variant="solid"
                  >
                    {refList.map((ref) => (
                      <ListboxItem
                        className={`${
                          ref.refstatus == 'Yes'
                            ? "border-green-400"
                            : "border-red-400"
                        } border bg-[#eeeeee] mb-3`}
                        key={ref.refid}
                      >
                        <span className="text-lg font-semibold">{ref.torefemail}</span> - {ref.refdate}{" "}
                      </ListboxItem>
                    ))}
                  </Listbox>
                </ModalBody>
              )}
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                {refState === "ref" && (
                  <Button color="primary" onPress={submitRef}>
                    Submit
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default MainNavbar;
